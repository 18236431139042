<template>
  <main class="main service-centers">
    <div class="container">
      <div class="main__inner">
        <section class="section section-pb48">
          <h2>Центры предоставления услуг</h2>
          <ul
            class="map-tabs"
            :class="{
              'hide-mobile':
                activeData !== null ||
                (activeContent !== null && (activeContent !== 2 || activeDistrict !== null)),
            }"
            v-if="mapReady"
          >
            <li class="map-tab map-tab-main">
              <button
                type="button"
                class="map-tab__btn"
                :class="{
                  'map-tab__btn--active': activeContent === 1,
                }"
                @click="setActiveContent(1)"
              >
                <MkalaIcon />
                <span class="map-tab__title">Муниципальные учреждения</span>
              </button>
            </li>
            <li class="map-tab map-tab-main">
              <button
                type="button"
                class="map-tab__btn"
                :class="{
                  'map-tab__btn--active': activeContent === 2,
                }"
                @click="setActiveContent(2)"
              >
                <MfcIcon />
                <span class="map-tab__title">МФЦ "Мои документы"</span>
              </button>
            </li>
            <template v-if="activeContent === 2">
              <li
                class="map-tab map-tab--faded"
                :class="{
                  'map-tab__btn--active': activeDistrict === i,
                }"
                @click="setActiveDistrict(i)"
                v-for="(district, i) in districts"
                :key="i"
              >
                <button type="button" class="map-tab__btn">
                  <MfcIcon />
                  <span class="map-tab__title">
                    {{ district.title }}
                  </span>
                </button>
              </li>
            </template>
          </ul>
          <LoadingIndicator title="Загрузка" v-if="!mapReady" />
          <div class="service-centers__map">
            <div class="map-info" v-if="mapList.length && (activeData !== null || activeContent !== null)">
              <div
                v-if="activeContent !== null && activeData === null"
                class="map-list__container"
                :class="{
                  'hide-mobile': activeContent === 2 && activeDistrict === null,
                }"
              >
                <button
                  class="map-list__item map-content__btn map-content__btn-mobile"
                  type="button"
                  @click="activeContent = null"
                >
                  <ArrowLeft />
                  <span v-if="activeContent === 1">Муниципальные учреждения</span>
                  <span v-else-if="activeContent === 2">
                    <template v-if="activeDistrict === null">МФЦ "Мои документы"</template>
                    <template v-else>{{ districts[activeDistrict].title }}</template>
                  </span>
                </button>
                <perfect-scrollbar tag="div" class="map-list">
                  <button
                    class="map-list__item"
                    type="button"
                    @click="setActiveData(i)"
                    v-for="(list, i) in mapList"
                    :key="i"
                  >
                    {{ list.title }}
                  </button>
                </perfect-scrollbar>
              </div>
              <div class="map-content" v-if="activeData !== null">
                <button
                  class="map-content__btn"
                  type="button"
                  @click="activeData = null"
                  v-if="activeContent !== null"
                >
                  <ArrowLeft />
                  <span>Назад</span>
                </button>
                <h2 class="map-content__title">{{ activeDataItem.title }}</h2>
                <perfect-scrollbar tag="div" class="map-content__items">
                  <div class="map-content__item" v-if="activeDataItem.address">
                    <div class="map-content__item-title">Адрес расположения:</div>
                    <div class="map-content__item-content">
                      {{ activeDataItem.address }}
                    </div>
                  </div>
                  <div class="map-content__item" v-if="activeDataItem.web_site || activeDataItem.url">
                    <div class="map-content__item-title">Веб-сайт:</div>
                    <a
                      :href="activeDataItem.web_site || activeDataItem.url | url"
                      target="_blank"
                      class="map-content__item-content"
                    >
                      {{ activeDataItem.web_site || activeDataItem.url }}
                    </a>
                  </div>
                  <div class="map-content__item" v-if="activeDataItem.email">
                    <div class="map-content__item-title">Электронная почта:</div>
                    <a :href="'mailto:' + activeDataItem.email" class="map-content__item-content">
                      {{ activeDataItem.email }}
                    </a>
                  </div>
                  <div class="map-content__item" v-if="activeDataItem.phone">
                    <div class="map-content__item-title">Справочный телефон:</div>
                    <a :href="'tel:' + activeDataItem.phone" class="map-content__item-content">
                      {{ activeDataItem.phone }}
                    </a>
                  </div>
                  <div class="map-content__item" v-if="activeDataItem.schedule">
                    <div class="map-content__item-title">Режим работы:</div>
                    <div class="map-content__item-content">
                      <template
                        v-if="
                          activeDataItem.type &&
                          activeDataItem.type.code === $store.state.type.CODE_DEPARTMENTS
                        "
                      >
                        <EditorJSComponent
                          :text="JSON.parse(activeDataItem.schedule)"
                          v-if="
                            activeDataItem.schedule &&
                            activeDataItem.schedule.blocks &&
                            activeDataItem.schedule.blocks.length
                          "
                        />
                        <span>Информация отсутствует</span>
                      </template>

                      <template v-else>
                        {{ activeDataItem.schedule }}
                      </template>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
            <yandex-map
              :settings="settings"
              :coords="cityCoords"
              :bounds="bounds"
              v-if="mapReady"
              :controls="controls"
              @map-was-initialized="mapInit"
              use-object-manager
            >
              <ymap-marker
                :marker-id="i"
                :coords="[item.address_x, item.address_y]"
                :icon="activeData === i ? activeIcon : icon"
                :key="i"
                v-for="(item, i) in mapList"
                ref="markers"
              />
            </yandex-map>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import MkalaIcon from "components/svg/MkalaIcon.vue";
import MfcIcon from "components/svg/MfcIcon.vue";
import ArrowLeft from "components/svg/ArrowLeft.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import LoadingIndicator from "components/LoadingIndicator.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "ServiceCenters",
  async asyncData({ store }) {
    await store.dispatch("GET_SERVICE_CENTERS_PAGE");
  },
  data() {
    return {
      bounds: [],
      cityCoords: [],
      controls: ["routeEditor", "trafficControl", "geolocationControl"],
      settings: {
        apiKey: "2accbdff-2038-4811-9f12-f3799d64a50c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#imageWithContent",
        imageHref: "/public/images/map-dark-blue-pin.svg",
        imageSize: [30, 30],
        imageOffset: [-15, -15],
      },
      activeIcon: {
        layout: "default#imageWithContent",
        imageHref: "/public/images/map-red-pin.svg",
        imageSize: [36, 36],
        imageOffset: [-18, -18],
      },
      mapReady: false,
      activeContent: null,
      activeDistrict: null,
      activeData: null,
    };
  },
  computed: {
    mapList() {
      if (this.activeContent !== null) {
        if (this.activeContent === 1) {
          return this.departments.filter((d) => {
            return d.address_x !== null || d.address_y !== null; // Не пустые адреса
          });
        }
        if (this.activeContent === 2) {
          return this.serviceCenters
            .filter((d) => {
              return d.address_x !== null || d.address_y !== null;
            })
            .filter((sc) => {
              if (this.activeDistrict !== null) {
                return sc.district_id === this.districts[this.activeDistrict].id;
              } else {
                return true;
              }
            });
        }
        return [];
      }
      return [].concat(this.departments).concat(this.serviceCenters);
    },
    departments() {
      if (this.$store.state.sc_page && this.$store.state.sc_page.departments) {
        return this.$store.state.sc_page.departments;
      }
      return [];
    },
    districts() {
      if (this.$store.state.sc_page && this.$store.state.sc_page.districts) {
        return this.$store.state.sc_page.districts;
      }
      return [];
    },
    activeDataItem() {
      if (this.activeContent === null) {
        return [].concat(this.departments).concat(this.serviceCenters)[this.activeData];
      }
      if (this.activeContent === 1 && this.departments[this.activeData]) {
        return this.departments[this.activeData];
      }
      if (this.activeContent === 2 && this.serviceCenters[this.activeData]) {
        return this.serviceCenters[this.activeData];
      }
      return false;
    },
    serviceCenters() {
      if (this.$store.state.sc_page && this.$store.state.sc_page.service_centers) {
        return this.$store.state.sc_page.service_centers;
      }
      return [];
    },
  },
  beforeMount() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      window.ymaps.ready(() => {
        window.ymaps.geocode("Россия, Республика Дагестан, Махачкала").then((res) => {
          this.cityCoords = res.geoObjects.get(0).geometry.getCoordinates();
        });
        this.updateMarkers();
      });
    });
  },
  methods: {
    mapInit(map) {
      map.geoObjects.events.add(["click"], (e) => {
        this.setActiveData(e.get("objectId"));
      });
    },
    sendNewMarkers(addresses, table_name) {
      let fd = new FormData();
      addresses
        .filter((d) => {
          return d.address_x !== null || d.address_y !== null; // Только найденные адреса сохраняются
        })
        .forEach((address, i) => {
          fd.append(`addresses[${i}][table_name]`, table_name);
          fd.append(`addresses[${i}][id]`, address.id);
          fd.append(`addresses[${i}][address_x]`, address.address_x);
          fd.append(`addresses[${i}][address_y]`, address.address_y);
        });
      if (addresses.length) {
        this.$store.dispatch("CACHE_ADDRESSES", fd);
      }
    },
    /**
     * Получение значений маркеров
     * и кэширование
     */
    updateMarkers() {
      // Департаменты которые без долготы и широты
      let depProm = Promise.all(
        this.$store.state.sc_page.departments
          .filter((d) => {
            return d.address_x === null || d.address_y === null;
          })
          .map(async (d) => {
            // Геокодирую через яндекс адрес и получаю дол и шир
            const res = await window.ymaps.geocode(d.address);
            if (res.geoObjects.get(0)) {
              const coords = res.geoObjects.get(0).geometry.getCoordinates();
              d.address_x = coords[0];
              d.address_y = coords[1];
            }
            return d;
          })
      ).then((vals) => {
        // Сохраняю долготу и широту полей
        this.sendNewMarkers(vals, "departments");
      });
      let scProm = Promise.all(
        this.$store.state.sc_page.service_centers
          .filter((d) => {
            return d.address_x === null || d.address_y === null;
          })
          .map(async (d) => {
            const res = await window.ymaps.geocode(d.address);
            if (res.geoObjects.get(0)) {
              const coords = res.geoObjects.get(0).geometry.getCoordinates();
              d.address_x = coords[0];
              d.address_y = coords[1];
            }
            return d;
          })
      ).then((vals) => {
        this.sendNewMarkers(vals, "service_centers");
      });
      // Жду пока закончатся запросы и показываю карту
      Promise.all([depProm, scProm]).then(() => {
        // Устанавливаю границы так чтобы внутры поместились все метки
        this.bounds = window.ymaps.util.bounds.fromPoints(
          this.mapList.map((m) => [m.address_x, m.address_y])
        );
        this.mapReady = true;
      });
    },
    setActiveContent(id) {
      if (this.activeContent === id) {
        this.activeContent = null;
      } else {
        this.activeContent = id;
      }
      this.activeDistrict = null;
      this.activeData = null;
    },
    setActiveDistrict(id) {
      if (this.activeDistrict === id) {
        this.activeDistrict = null;
      } else {
        this.activeDistrict = id;
      }
      this.activeData = null;
    },
    setActiveData(id) {
      this.activeData = id;
    },
  },
  components: {
    LoadingIndicator,
    ArrowLeft,
    MfcIcon,
    MkalaIcon,
    yandexMap,
    ymapMarker,
    PerfectScrollbar,
    EditorJSComponent,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/services/index.styl"
@import "~@/styles/parts/perfect-scrollbar.styl"
.service-centers {
  &__map {
    width 100%
    height 720px
    position relative
    +below(768px) {
      height auto
    }

    .ymap-container {
      width 100%
      height 100%
      +below(768px) {
        height 550px
      }
      +below(540px) {
        height 420px
      }
    }
  }

  .map-tabs {
    margin-bottom 24px
    +below(420px) {
      margin-bottom: 4px;
    }
  }

  .hide-mobile {
    +below(768px) {
      display none
    }
  }
}

.map-info {
  margin = 48px
  margin margin 8px
  width 295px
  height "calc(100% - (%s * 2))" % margin
  background var(--color_white)
  position absolute
  z-index 1
  box-shadow: 1px 2px 5px var(--color_black_o25);
  border-radius: var(--radius);
  padding 24px
  box-sizing border-box
  +below(768px) {
    position static
    margin 0 0 12px
    box-shadow none
    border-radius 0
    padding 0
    width auto
    height auto
  }
}

.map-tabs {
  display flex
  flex-wrap wrap
  gap 16px
  width 100%
  +below(768px) {
    flex-direction column
    align-items flex-start
    gap 4px
  }
}

.map-tab {
  display inline-flex

  &-main &__btn {
    +below(768px) {
      padding 12px 8px
    }
  }

  &--faded {
    opacity 0.8
  }

  &__btn {
    background none
    border none
    cursor pointer
    padding 12px 16px
    display inline-flex
    align-items center
    transition .2s

    &--active
    &:hover {
      background: var(--color_gray_light);
      border-radius: var(--radius);
    }

    .icon {
      width 24px
      height 24px
      margin-right 8px

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
  }
}

.map-content {
  display flex
  flex-direction column
  height 100%

  &__title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_dark);
    margin-bottom 16px
    flex-shrink 0
  }

  &__item {
    margin-bottom 16px
    display grid
    grid-template-rows auto auto
    grid-gap 4px
  }

  &__item-title {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 18px;
    color: var(--color_dark);
  }

  &__item-content {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
    word-break break-word
  }

  &__btn {
    background none
    border none
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
    text-align left
    display grid
    grid-template-columns 24px auto
    grid-gap 8px
    cursor pointer
    border-bottom 1px solid var(--color_gray_divider)
    padding-bottom 16px
    flex-shrink 0
    +below(420px) {
      padding 12px 8px
      border-radius 4px
      border-bottom none
      background-color var(--color_gray_light)
    }

    &.map-content__btn-mobile {
      width 100%
      margin-bottom 16px
      border-bottom: 1px solid var(--color_gray_divider);
      +above(769px) {
        display none
      }
      +below(420px) {
        padding 12px 8px
        border-radius 4px
        border-bottom none
        background-color var(--color_gray_light)
      }
    }

    .icon {
      width 24px
      height 24px

      svg {
        width 100%
        height 100%

        path {
          fill var(--color_dark);
        }
      }
    }
  }
}

.map-list {
  display flex
  flex-direction column
  overflow hidden
  position relative
  height 100%
  +below(768px) {
    max-height 400px
    overflow-y auto
  }
  +below(420px) {
    max-height 300px
  }

  &__container {
    height 100%
  }

  &__item {
    flex-shrink 0
    background none
    border none
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
    text-align left
    padding-bottom 16px
    cursor pointer

    &:not(:first-child) {
      padding-top 16px
      border-top 1px solid var(--color_gray_divider)
    }
  }
}
</style>
