<template>
  <figure class="icon mfc-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9493 2.12931C11.3288 1.75287 11.6988 1.36691 12.0831 1C12.0641 2.54387 12.0688 4.08297 12.0783 5.62683C12.0831 6.78473 12.0783 7.94739 12.0783 9.10529C10.9256 9.10053 9.77282 9.11006 8.62481 9.10053C7.08781 9.08623 5.55556 9.09576 4.01855 9.09576C6.32406 6.77043 8.63905 4.45464 10.9493 2.12931Z"
        fill="#333333"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0786 1C14.7636 1.00953 17.4486 1.00476 20.1336 1.00476C20.1384 4.96926 20.1336 8.93375 20.1336 12.8982C20.0909 14.5708 19.2513 16.1956 17.942 17.2201C17.5577 17.5298 17.1166 17.7633 16.6944 18.0111C15.7978 18.5305 14.9059 19.0499 14.0093 19.5693C13.6156 19.7885 13.2408 20.0315 12.8424 20.2364C12.5767 20.3174 12.3443 20.4746 12.1023 20.608C12.0264 20.2983 12.0691 19.9791 12.0644 19.6646C12.0644 18.9927 12.0549 18.3256 12.0691 17.6537C12.0739 16.8866 12.0739 16.1194 12.0691 15.357C12.8851 14.8614 13.72 14.3945 14.5454 13.9132C14.8111 13.7512 15.1147 13.6273 15.2997 13.37C15.4562 13.1698 15.5369 12.9173 15.5321 12.66C15.5274 10.3156 15.5369 7.97122 15.5274 5.63159C14.3794 5.63159 13.2266 5.63159 12.0786 5.63159C12.0691 4.08296 12.0596 2.5391 12.0786 1Z"
        fill="#FF6B55"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.00015 9.09589H4.01439C5.55139 9.09589 7.08839 9.08636 8.62065 9.10065C8.6159 11.0495 8.62065 12.9984 8.62065 14.9426C8.61116 15.2142 8.69655 15.481 8.86258 15.6954C9.0381 15.9289 9.3085 16.048 9.55518 16.191C10.3901 16.677 11.2345 17.1583 12.0647 17.6491C12.0504 18.321 12.0599 18.9881 12.0599 19.6599C12.0647 19.9744 12.0267 20.2937 12.0979 20.6034C12.3398 20.47 12.5723 20.3127 12.8379 20.2317C12.6007 20.4033 12.3398 20.5367 12.0836 20.6796C12.0694 21.4516 12.0931 22.2283 12.0694 23.0002C10.3379 21.9948 8.60167 20.9894 6.87017 19.9839C5.68896 19.2978 4.76866 18.178 4.32274 16.8819C4.09978 16.2482 3.99541 15.5715 4.00015 14.8997C4.00015 12.9603 4.00015 11.0305 4.00015 9.09589Z"
        fill="#D59067"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "MfcIcon",
};
</script>
